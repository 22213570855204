import React, { useState } from 'react';
import './Témoignages.scss';

const Testimonials = () => {
  const [visibleCount, setVisibleCount] = useState(3);
  const [showMore, setShowMore] = useState(true);

  const handleShowMoreLess = () => {
    if (showMore) {
      setVisibleCount(oldCount => {
        const newCount = oldCount + 3;
        if (newCount >= testimonials.length) {
          setShowMore(false);
        }
        return newCount;
      });
    } else {
      setVisibleCount(3);
      setShowMore(true);
    }
  };

  const testimonials = [
    // Remplacer par les vrais témoignages
    { name: 'Seiya', testimonial: 'Un excellent service de streaming ! J\'ai pu supprimer mes abonnements aux autres plateformes de streaming.' },
    { name: 'Killua', testimonial: 'Je trouve toujours ce que je veux regarder, il y a énormément de choix.' },
    { name: 'Jean', testimonial: 'Facile à utiliser sur tous les écrans, site web et appli très fluides autant dans la navigation que le visionnage du contenu.' },
    { name: 'Amour', testimonial: 'Un grand choix d\'animes en VOSTFR, il manque juste quelques drama coréens' },
    { name: 'Anonyme (Test)', testimonial: 'Tous les Marvel sont là, je prends un abonnement dans la semaine, merci pour l\'essai gratuit !' },
  ];

  return (
    <div className="testimonials-section" id="témoignages">
      <h1>Témoignages</h1>
      <div className="testimonials-container">
        {testimonials.slice(0, visibleCount).map((testimonial, index) => (
          <div className="testimonial" key={index}>
            <h3>{testimonial.name}</h3>
            <p>{testimonial.testimonial}</p>
          </div>
        ))}
      </div>
      {(showMore || visibleCount > 3) && (
        <button onClick={handleShowMoreLess}>{showMore ? 'Voir plus' : 'Voir moins'}</button>
      )}
    </div>
  );
}

export default Testimonials;