import React from 'react';
import AnimeSection from './Anime/Anime';
import FilmSection from './Films/Films';
import SerieSection from './Series/Series';

function Catalogue() {
    return (
        <div id="catalogue">
            <AnimeSection />
            <FilmSection />
            <SerieSection />
        </div>
    );
}

export default Catalogue;