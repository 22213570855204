import React, { useState, useEffect } from 'react';
import Carousel from '../Carousel/Carousel';
import Card from '../Card/Card';
import './Anime.scss';
import OP from '../../../../assets/onepiece.jpg';
import DB from '../../../../assets/db.jpg';
import Simulcast from '../../../../assets/simulcast.jpg';

const TMDB_API_KEY = "57da1142c8ae565eb0a535ea2c0e3fe8"; 

// Liste des ID des titres que vous souhaitez afficher
const animeIds = [ 
    // Remplacez ces numéros par les ID des titres que vous souhaitez afficher
    100565, 114410, 131041, 154494, 211057,  65942, 82739, 63926, 203737, 80564, 119100, 105009, 75208, 83103, 86031, 117465, 69291, 37854, 88803, 45790, 42444, 1429, 120089, 123876, 204832, 30981, 100281, 31911, 46298, 83121, 12971, 85937, 72517, 100049, 90937, 117933
];

function AnimeSection() {
  const [animeList, setAnimeList] = useState([]);
  const cards = [
    { icon: DB, title: '220 animes en VOSTFR', argument: 'Retrouvez les meilleurs animes de tous les temps, en VOSTFR, de Dragon Ball en 1986 à Oshi no Ko en 2023.' },
    { icon: Simulcast, title: 'Simulcast', argument: 'De nouveaux épisodes chaque semaine, le jour même de leur sortie, parce qu\'un fan n\'attend pas.' },
    { icon: OP, title: 'Séries complètes', argument: 'Retrouvez les animes le plus longs : One Piece, Naruto, Dragon Ball, Bleach, Hunter x Hunter... Ils sont tous complets ou en simulcast, chez Aniflix.' },
  ];
  
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    const promises = animeIds.map(id => 
      fetch(`https://api.themoviedb.org/3/tv/${id}/images?api_key=${TMDB_API_KEY}`)
        .then(response => response.json())
        .then(data => {
          let poster;
          const englishPoster = data.posters.find(poster => poster.iso_639_1 === 'en');
          const noLanguagePoster = data.posters.find(poster => poster.iso_639_1 === 'null');
          const defaultPoster = data.posters[0];

          if (englishPoster) {
            poster = englishPoster.file_path;
          } else if (noLanguagePoster) {
            poster = noLanguagePoster.file_path;
          } else {
            poster = defaultPoster ? defaultPoster.file_path : '';
          }

          return poster;
        })
    );

    Promise.all(promises)
      .then(animeArray => setAnimeList(animeArray))
      .catch(err => console.error(err));
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <div className="anime-section">
      <h1 className='title'>Animes</h1>
      <Carousel images={animeList.map(poster => `https://image.tmdb.org/t/p/w500${poster}`)} />
      <div className="anime-cards">
        {windowWidth <= 768 ? cards.slice(0, 1).map((card, index) => (
          <Card key={index} {...card} />
        )) : 
        cards.map((card, index) => (
          <Card key={index} {...card} />
        ))}
      </div>
    </div>
  );
}

export default AnimeSection;