import React from 'react';
import './BannerEvent.scss';

function BannerEvent() {
    return (
        <div className="banner-event">
            <div className="banner-image-event"></div>
            <div className="banner-info-event">
                <h1>Suzume no Tojimari</h1>
                <p>Dans une petite ville de Kyushu, Suzume, 17 ans, suit un homme énigmatique jusqu'à une porte ancienne dans les montagnes. En l'ouvrant, des portes similaires apparaissent dans tout le Japon, libérant des catastrophes. Suzume entreprend alors un voyage mystique pour fermer ces portes, s'aventurant dans un espace où le temps se confond.</p>
            </div>
        </div>
    );
}

export default BannerEvent;