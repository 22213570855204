import React from 'react';
import BannerEvent from '../Components/Actu/Banner/BannerEvent'
import Next from '../Components/Actu/Next/Next';
import Blog from '../Components/Actu/Blog/Blog';
import Event from '../Components/Actu/Event/Event';
import Add from '../Components/Actu/Ajouts/Add';

function Actu() {
  return (
    <div className="actu-page">
      <BannerEvent />
      <Event />
      <Add />
      <Next />
      <Blog />
    </div>
  );
}

export default Actu;