import React, { useState } from 'react';
import './Blog.scss';
import img1 from '../../../assets/User/joker.jpg';
import img2 from '../../../assets/User/luigi.jpg'
import img3 from '../../../assets/User/kratos.jpg'

const Blog = () => {
  const [visibleCount, setVisibleCount] = useState(3);
  const [showMore, setShowMore] = useState(true);

  const handleShowMoreLess = () => {
    if (showMore) {
      setVisibleCount(oldCount => {
        const newCount = oldCount + 3;
        if (newCount >= blogPosts.length) {
          setShowMore(false);
        }
        return newCount;
      });
    } else {
      setVisibleCount(3);
      setShowMore(true);
    }
  };

  const blogPosts = [
    { 
      author: 'Joker', 
      img: img1, 
      date: '27 Mai 2023', 
      title: 'Sortie des Séries', 
      content: `Eh bien, mes petits spectateurs déments... Vous avez remarqué la disparition de votre Joker préféré pendant un mois ? Pas de panique, mes petits monstres, j'étais tout simplement occupé à préparer une surprise savoureuse pour vous.
      Imaginez, plus de 120 séries sensationnelles ont miraculeusement débarqué, tels des cadeaux de votre Joker favori. Des pépites qui ont marqué des générations, comme Breaking Bad, la Casa de Papel, les aventures interminables des Simpson et la famille déjantée de Malcolm... Ces classiques intemporels sont maintenant à portée de clic pour votre plaisir démoniaque.\n
      Mais, suis-je vraiment le Joker si je me contente de l'ordinaire ? Alors voilà pour vous, mes précieux anarchistes du chaos, du contenu inédit, prêt à être dévoré. La dernière sensation d'espionnage, The Night Agent, et les délires interdimensionnels de Rick & Morty Saison 6, n'attendent que de vous ensorceler.\n
      Alors, asseyez-vous, détendez-vous et laissez le Joker vous guider dans la spirale du meilleur du streaming. Qui a besoin de la réalité quand le chaos est tellement plus divertissant ?`
    },
    { 
      author: 'Luigi', 
      img: img2, 
      date: '24 Mai 2023', 
      title: 'Programme Affiliation', 
      content: `Ah, mamma mia ! C'est Luigi ici, avec des nouvelles excitantes à partager avec vous tous !\n
      Nous ouvrons les portes de notre programme d'affiliation. C'est une belle opportunité, mes amis, spécialement conçue pour nos abonnés les plus fidèles. Imaginez, générer un revenu passif à long terme, tout en invitant de nouveaux visages à découvrir notre merveilleuse plateforme de streaming. Cela ressemble à un rêve, non ?\n
      Mais ce n'est pas un rêve, mes chers amis ! Tout ce que vous avez à faire, c'est de convaincre de nouveaux clients de tester notre plateforme et voilà, vous êtes récompensé. Plus vous apportez de clients, plus vous gagnez. C'est aussi simple que de sauter par-dessus un Goomba !\n
      Vous vous demandez comment en savoir plus, n'est-ce pas ? Eh bien, il suffit de nous envoyer un message via notre formulaire de contact, Twitter ou Instagram. Toutes les informations dont vous avez besoin sont à portée de main. Alors, embarquez dans cette aventure avec Luigi, et voyons jusqu'où elle peut vous mener !`
    },
    {
      author: 'Kratos',
      img: img3, 
      date: '12 Mai 2023', 
      title: 'Création du Site Web', 
      content: `Aniflix s'est sculpté une présence, une identité. Notre portail web est maintenant forgé dans le métal et le feu, prêt à servir. Un véritable aréopage de divertissements vous attend : notre catalogue exhaustif, les nouveautés sur le point de déferler, les événements qui grondent à l'horizon, et même une carte de navigation pour ceux qui sont nouveaux dans ce royaume. Entrez donc, si vous osez.\n
      En vérité, nous avons travaillé sans relâche, chacun apportant son marteau et son enclume, pour forger cette plateforme. Vous y trouverez la puissance des Titans et la sagesse des dieux. N'hésitez pas à explorer, à plonger dans ce maelström de contenus, car, comme moi, Kratos, chaque bataille, chaque défi vous rendra plus fort.\n
      Entrez donc, courageux voyageurs, dans ce nouveau monde. C'est un lieu où se mélangent la fantaisie et la réalité, où chaque passionné trouvera sa quête. Aniflix, notre portail, est ouvert. Alors, prenez votre épée et votre bouclier et entrez dans l'arène.`
    },
    {
      author: 'Joker', 
      img: img1, 
      date: '24 Avril 2023', 
      title: 'Sortie des Films', 
      content: `Eh bien, mes petits camarades du chaos, que diriez-vous d'un tourbillon de films à votre disposition ? Oh, nous avons atteint l'insaisissable seuil des 850 films pour votre plaisir débridé. Nous avons traversé le temps depuis les jours naïfs de 1937, où une certaine Blanche-Neige ensorcelait les cœurs, jusqu'à la pointe de 2023 avec le délire numérique de Super Mario Bros. Que de mémoires cinématographiques, n'est-ce pas ? Un buffet copieux du meilleur du cinéma est prêt à être dévoré... J'ose dire, ça n'attend que vous, mes chers anarchistes du streaming. Alors, qui est prêt pour un festin de films ?\n
      Vous pensez que c'est tout ? Oh, non, mes précieux acolytes du chaos. Imaginez un monde où chaque époque, chaque genre, chaque mythe et légende est révélé par le simple clic d'un bouton. Des comédies qui vous feront rire aux larmes, des drames qui vous feront pleurer de chagrin, des thrillers qui vous feront frissonner d'anticipation, et des histoires d'amour qui vous feront battre le cœur. Tout cela, et bien plus encore, est à portée de main.\n
      Alors, mes chers acolytes du chaos, pourquoi se contenter d'un seul genre quand on peut goûter à tous ? Entrez dans la folie cinématographique, perdez-vous dans le labyrinthe du rire, de l'amour, de l'horreur, de l'excitation. Après tout, c'est le Joker qui vous y invite, alors ça ne peut être qu'un tourbillon de plaisir débridé, n'est-ce pas ?`
    },
    { 
      author: 'Kratos', 
      img: img3, 
      date: '21 Avril 2023', 
      title: 'Sortie des Animes', 
      content: `Le destin d'Aniflix est d'abord de satisfaire l'insatiable appétit des otakus de notre belle France, en offrant une nourriture riche de la plus haute qualité en matière d'anime. Nous nous engageons dans l'arène du simulcast pour délivrer l'écho des histoires fraîchement créées, car nos abonnés ne méritent rien de moins. Vous découvrirez également les titans qui ont défini des générations d'animes : One Piece, Naruto, Dragon Ball, Hunter x Hunter... Ils sont là, prêts pour le combat.\n
      Nous avons parcouru des distances incalculables, bravé des tempêtes de feu, traversé des mers tumultueuses pour vous apporter les animes les plus exquis. Chaque série, chaque épisode, chaque minute de visionnage est une histoire à raconter, une émotion à ressentir. Un voyage à travers les vastes univers de l'anime, des montagnes majestueuses de la fantasy épique aux profondeurs sombres des thrillers psychologiques.\n
      Alors préparez-vous, mes chers otakus, car le voyage ne fait que commencer. Avec chaque saison, de nouveaux titres vont s'épanouir, de nouveaux mondes seront explorés et de nouvelles histoires seront racontées. C'est le rêve d'Aniflix, c'est notre serment. Nous continuerons à livrer le meilleur de l'anime, car comme le dit le guerrier : la bataille ne fait que commencer.`
    },

  ];

  return (
    <div className="blog-section" id="news">
      <h1 className="title">Actualités</h1>
      <div className="blog-container">
        {blogPosts.slice(0, visibleCount).map((post, index) => (
          <div className="blog-post" key={index}>
            <div className="post-header">
              <img src={post.img} alt={`Avatar of ${post.author}`} className="post-author-avatar" />
              <div className="author-info">
                <span className="post-author">{post.author}</span>               
                <span className="post-date">{post.date}</span>
              </div>
            </div>
            <h3 className="post-title">{post.title}</h3>
            <div className="post-content">
              {post.content.split('\n').map((line, idx) => <p key={idx}>{line}</p>)}
            </div>
            </div>
        ))}
      </div>
      {(showMore || visibleCount > 3) && (
        <div className="blog-button-container">
          <button className="blog-button" onClick={handleShowMoreLess}>
            {showMore ? 'Voir plus' : 'Voir moins'}
          </button>
        </div>
      )}
    </div>
  );
      }

export default Blog;
