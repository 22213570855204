import React, { useState } from 'react';
import './Event.scss';
import eventData from './eventData';

function Event() {
    const [selectedDate, setSelectedDate] = useState(eventData[0].date);

    const handleDateChange = event => {
        setSelectedDate(event.target.value);
    };

    return (
        <div className="event" id="event">
            <div className="event-selection">
                <label htmlFor="event-date-select">Evènements le :</label>
                <select id="event-date-select" onChange={handleDateChange}>
                    {eventData.map((event, index) => (
                        <option key={index} value={event.date}>{event.date}</option>
                    ))}
                </select>
            </div>
            {eventData.filter(event => event.date === selectedDate).map((event, index) => (
                <div className="event-details" key={index}>
                    <img src={event.image} alt={event.title} className="event-banner"/>
                    <div className="event-info">
                        <h2>{event.title}</h2>
                        <p>{event.date}</p>
                        <p>{event.description}</p>
                        <button className="event-button">S'inscrire</button>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Event;
