import React, { useState, useEffect } from 'react';
import Carousel from '../Carousel/Carousel';
import Card from '../Card/Card';
import './Films.scss'; 
import Genres from '../../../../assets/Genres.png';
import Movies from '../../../../assets/900.jpg';
import Cinema from '../../../../assets/cinema.jpg';

const TMDB_API_KEY = "57da1142c8ae565eb0a535ea2c0e3fe8"; 

// Liste des ID des titres que vous souhaitez afficher
const movieIds = [ 
    // Remplacez ces numéros par les ID des titres que vous souhaitez afficher
    502356, 713704, 603692, 76600, 10386, 877703, 677179, 934433, 315162, 507086, 238, 1885, 111, 580489, 372058, 505642, 338953, 299534, 340102, 383498, 425, 675, 424694, 106646, 1895, 348, 9479, 616820, 77338, 12230, 58, 385687, 330457, 9479, 176, 9502
];

function FilmSection() {
  const [movieList, setMovieList] = useState([]);

  const cards = [
    { icon: Movies, title: '1 000 films à votre disposition', argument: 'Retrouvez nos films de 1937 à 2023, du classique à la nouveauté, vous trouverez forcément votre bonheur.' },
    { icon: Cinema, title: 'Mise à jour régulière', argument: 'Chez Aniflix, vous trouverez de nouveaux films chaque semaine, et des nouveautés environ 1 mois après leur sortie au cinéma !' },
    { icon: Genres, title: 'Large choix de genres', argument: 'Nos titres sont classés par Genres : Action, Comédie, Drame, Super-Héros, Fantastique, Romance, Thriller, Horreur, Disney... Nous avons tout, sur Aniflix.' },
  ];

  useEffect(() => {
    const promises = movieIds.map(id => 
      fetch(`https://api.themoviedb.org/3/movie/${id}?api_key=${TMDB_API_KEY}&language=fr`)
        .then(response => response.json())
    );

    Promise.all(promises)
      .then(movieArray => setMovieList(movieArray))
      .catch(err => console.error(err));
  }, []);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <div className="film-section">
      <h1 className='title'>Films</h1>
      <Carousel images={movieList.map(movie => `https://image.tmdb.org/t/p/w500${movie.poster_path}`)} />
      <div className="film-cards">
        {windowWidth <= 768 ? cards.slice(0, 1).map((card, index) => (
          <Card key={index} {...card} />
        )) : 
        cards.map((card, index) => (
          <Card key={index} {...card} />
        ))}
      </div>
    </div>
  );
}

export default FilmSection;