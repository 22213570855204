import Event1 from '../../../assets/Event/Event1.jpg';
import Event2 from '../../../assets/Event/Event2.jpeg';
import Event3 from '../../../assets/Event/Event3.jpg';

const eventData = [
    {
        date: "A Venir",
        image: Event1,
        title: "Le Ciné du Samedi",
        description: "Vous n'avez pas pu le voir au cinéma ? On vous offre une deuxième chance en proposant le visionnage du film gratuitement ! L'évènement sera bientôt disponible, nous sommes en train de créer une plateforme pour le faire au mieux.",
    },
    {
        date: "Bientôt",
        image: Event3,
        title: "Quiz Musical",
        description: "L'évènement sera bientôt disponible, nous attendons d'avoir une vingtaine d'abonnés. Réussirez-vous à deviner de quels films viennent ces 25 musiques ? De John Williams à Danny Elfman en passant par Hans Zimmer, écoutez les meilleurs BO du cinéma. Le meilleur score gagne un abonnement de 2 mois !",
    },
]

export default eventData;