import React, { useState } from 'react';
import './Mobile.scss';
import Host from '../../../assets/Guide/Host.jpg';
import Connect from '../../../assets/Guide/Connect.jpg';
import Host2 from '../../../assets/Guide/Host.jpg';
import Connect2 from '../../../assets/Guide/Connect.jpg';

function Mobile() {
    const [step, setStep] = useState(1);
    const [installedOnTV, setInstalledOnTV] = useState(null);
    const [installedOnBox, setInstalledOnBox] = useState(null);
    const [serverDetailsAdded, setServerDetailsAdded] = useState(null);
    const [quickInterfaceSeen, setQuickInterfaceSeen] = useState(null);
    const [chromecastInstalled, setChromecastInstalled] = useState(null);

    const handleBack = () => {
        if (step === 4 || step === 5) {
            setStep(1);
        } else {
            setStep(step - 1);
        }
    };

    const renderStep = () => {
        switch(step) {
            case 1:
                return (
                    <div>
                        <p>Cherchez Emby dans le Store de votre TV Connectée et installez l'application.</p>
                        <button onClick={() => { setInstalledOnTV(true); setStep(2); }}>Oui, c'est fait</button>
                        <button onClick={() => { setInstalledOnTV(false); setStep(5); }}>Non, je n'ai pas trouvé</button>
                    </div>
                );
            case 2:
                return (
                    <div>
                        <p>Ouvrez l'application et ajouter un serveur avec ces éléments : emby.aniflix.fr en haut et 8096 en bas. (Image 2)</p>
                        <button onClick={() => { setServerDetailsAdded(true); setStep(4); }}>Oui, c'est fait</button>
                        <button onClick={() => { setServerDetailsAdded(false); setStep(3); }}>Non, je ne comprends pas</button>
                    </div>
                );
            case 3:
                return (
                    <div>
                        <p>Vous êtes sûrement tombés sur l'interface de connexion rapide, appuyez sur passer. (Image 1)</p> 
                        <button onClick={() => { setQuickInterfaceSeen(true); setStep(2); }}>Oui, c'est fait</button>
                    </div>
                );
            case 4:
                return (
                    <div>
                        <p>Félicitations ! Vous avez réussi à installer Emby sur votre TV, votre Box ou vous pouvez utiliser votre Chromecast. Bon visionnage !</p>
                    </div>
                );
            case 5:
                return (
                    <div>
                        <p>Installez Emby sur votre Box (Free, SFR, Bouygues...).</p>
                        <button onClick={() => { setInstalledOnBox(true); setStep(2); }}>Oui, c'est fait</button>
                        <button onClick={() => { setInstalledOnBox(false); setStep(6); }}>Non, je n'ai pas trouvé</button>
                    </div>
                );
            case 6:
                return (
                    <div>
                        <p>Il va falloir utiliser le Chromecast, branchez le et installez le selon les modalités fournies avec.</p>
                        <button onClick={() => { setChromecastInstalled(true); setStep(4); }}>Oui, c'est fait</button>
                        <button onClick={() => { setChromecastInstalled(false); setStep(7); }}>Non, je n'ai pas réussi</button>
                    </div>
                );
                case 7:
                return (
                    <div>
                        <p>Suivez ce lien pour installer le Chromecast comme il faut</p>
                        <a href="https://support.google.com/chromecast/answer/2998456?hl=fr&co=GENIE.Platform%3DAndroid"> Installer un Chromecast </a>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className='mobile'>
          <h1>Guide de Démarrage</h1>
            <div className='mobile-description'>
                <p>Vous trouverez ici deux guides, celui sur Téléphone et Tablette est assez simple, celui sur TV plus complexe, mais normalement il prend en compte tous les scénarios possibles, n'hésitez pas à utiliser le formulaire de Contact en cas de problème ! </p>
            </div>
            <div className="card-mobile">
                <div className='mobile-content'>
                    <div className='mobile-images'>
                        <img className='img-mobile' src={Connect} alt="Installation sur mobile" />
                        <img className='img-mobile' src={Host} alt="Installation sur mobile" />
                    </div>
                    <div className='mobile-text'>
                    <h2 className='h2-mobile'>Installation sur Téléphone / Tablette</h2>
                        <ol>
                            <li>Allez sur le Google Play Store / Apple App Store.</li>
                            <li>Recherchez l'application Emby et installez-la.</li>
                            <li>Ouvrez l'application et vous allez tomber sur une interface de connexion Emby Connect, appuyez sur passer. (Image 1)</li>
                            <li>Rejoignez le serveur en entrant les détails du serveur : emby.aniflix.fr pour le haut et 8096 pour le bas. (Image 2)</li>
                            <li>Profitez du contenu, n'hésitez pas à parcourir toute la plateforme pour découvrir l'espace de recherche, les collections, le tri...</li>
                        </ol>
                         <div className='div'>
                            <h2 className='h2-mobile'>Installation sur TV / Box / Chromecast</h2>
                                {renderStep()}
                                {step > 1 && <button className="btn-back" onClick={handleBack}>Retour</button>}
                         </div>
                    </div>
                </div>
                <img className='img-mobile-2' src={Connect2} alt="Installation sur mobile" />
                 <img className='img-mobile-2' src={Host2} alt="Installation sur mobile" />
            </div> 
            </div>
    );
}

export default Mobile;