import { React, useState, useEffect } from 'react';
import './Categories.scss';

const Categories = () => {
  const categories = [
    { name: 'Comédie', count: 443, list: ['Saiki Kusuo', 'Non Non Biyori', 'Astérix & Obélix', 'Deadpool', "L'Age de Glace", 'Malcolm', 'Les Simpson', 'Rick & Morty...'] },
    { name: 'Drame', count: 506, list: ['Banana Fish', 'Death Note', 'Fight Club', 'Joker', 'La Liste de Schindler', 'Black Mirror', 'Breaking Bad', 'Game of Thrones...'] },
    { name: 'Horreur', count: 105, list: ['Another', 'Devilman Crybaby', 'Alien', 'Halloween', 'Scream', 'Conjuring', 'The Last of Us', 'Supernatural...'] },
    { name: 'Action', count: 539, list: ['Baki', 'Chainsaw Man', 'Athena', 'Bullet Train', 'Deadpool', 'Fast & Furious', 'John Wick', 'The Night Agent', 'Vikings...'] },
    { name: 'Romance', count: 194, list: ['Kaguya-Sama', 'Clannad', 'Seishun Buta Yarou', '50 Nuances de Grey', 'Nos Etoiles Contraires', 'Ghost', 'Gossip Girl', "Grey's Anatomy..."] },
    { name: 'Fantastique', count: 325, list: ['Attack on Titan', 'Demon Slayer', 'Avatar', 'Harry Potter', 'Avengers', 'Heroes', 'Locke & Key', 'Mandalorian...'] },
    { name: 'Western', count: 14, list: ['Cowboy Bebop', 'le Bon, la Brute et le Truand', '1883', 'The Revenant', "Les Huit Salopards", 'Django Unchained', 'La Horde Sauvage', '1923...'] },
    { name: 'Disney', count: 120, list: ['La Reine des Neiges', 'Le Roi Lion', 'Cruella', 'Cars', "Nemo", 'Lilo & Stitch', 'Toy Story', 'Bambi', 'Hercule', 'Peter & Eliott le Dragon', 'Zootopie...'] },
  ];

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [displayedCategories, setDisplayedCategories] = useState(windowWidth >= 768 ? categories : categories.slice(0, 4));

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setDisplayedCategories(windowWidth >= 768 ? categories : categories.slice(0, 4));
  }, [windowWidth]);

  return (
    <div className="categories-section" id="categories">
      <div className="categories-text">
        <h1>Catégories</h1>
      </div>
      <div className="categories-container">
        {displayedCategories.map((category, index) => (
          <div key={index} className={`category category-${index % 8}`}>
            <h3>{category.name}</h3>
            <p className="count">{category.count} Titres</p>
            <p>{category.list.join(', ')}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Categories;