import React from 'react';
import AboutUs from '../Components/About/AboutUs';
import ContactForm from '../Components/Home/Contact/Contact';

function About() {
  return (
    <div className="about-page">
      <AboutUs />
      <ContactForm />
    </div>
  );
}

export default About;