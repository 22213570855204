import React, { useState, useEffect } from 'react';
import './Carousel.scss';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const Carousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [displayCount, setDisplayCount] = useState(() => {
    if (window.innerWidth <= 768) {
      return 1;
    } else if (window.innerWidth > 768 && window.innerWidth <= 2200) {
      return 6;
    } else {
      return 6;
    }
  });
  
  useEffect(() => {
    const handleResize = () => {
        if (window.innerWidth <= 768) {
            setDisplayCount(1);
        } else if (window.innerWidth > 768 && window.innerWidth <= 2200) {
            setDisplayCount(6);
        } else {
            setDisplayCount(6);
        }
    };

    window.addEventListener('resize', handleResize);

    return () => {
        window.removeEventListener('resize', handleResize);
    };
}, []);

  const visibleImages = images.slice(currentIndex, currentIndex + displayCount);

  const handlePrevious = () => {
    setCurrentIndex(oldIndex => oldIndex === 0 ? images.length - displayCount : oldIndex - displayCount);
  };

  const handleNext = () => {
    setCurrentIndex(oldIndex => oldIndex === images.length - displayCount ? 0 : oldIndex + displayCount);
  };

  return (
    <div className="carousel">
      <IconButton sx={{ backgroundColor: 'rgba(0,0,0,0.4)', '&:hover': { backgroundColor: 'darkred' }}} onClick={handlePrevious}>
        <ArrowBackIcon sx={{ color: "white", fontSize: "2rem" }} />
      </IconButton>
      <div className="carousel-images">
        {visibleImages.map((image, index) => (
          <div className="carousel-image" key={index}>
            <img src={image} alt={`Anime ${currentIndex + index + 1}`} />
          </div>
        ))}
      </div>
      <IconButton sx={{ backgroundColor: 'rgba(0,0,0,0.4)', '&:hover': { backgroundColor: 'darkred' }}} onClick={handleNext}>
        <ArrowForwardIcon sx={{ color: "white", fontSize: "2rem" }} />
      </IconButton>
    </div>
  );
};

export default Carousel;