import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.scss';
import Logo from '../../../assets/logo.png';
import { FaBars, FaTimes } from 'react-icons/fa'; 

function NavBar() {
    const [showMenu, setShowMenu] = useState(false);
    const [showSubMenuPage, setShowSubMenuPage] = useState(false);
    const [showAboutSubMenu, setShowAboutSubMenu] = useState(false);
    const [showNewsSubMenu, setShowNewsSubMenu] = useState(false);
    const [scrolled, setScrolled] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
    };

    const closeMenu = () => {
        setShowMenu(false);
        setShowSubMenuPage(false);
        setShowAboutSubMenu(false);
        setShowNewsSubMenu(false);
    }

    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 200 ) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    }

    useEffect(() => {
        const hashChangeHandler = () => {
            closeMenu();
        };
    
        window.addEventListener('hashchange', hashChangeHandler);
    
        return () => {
            window.removeEventListener('hashchange', hashChangeHandler);
        };
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    const handleSubMenuClick = () => {
        setShowSubMenuPage(true);
    }

    const handleAboutSubMenuClick = () => {
        setShowAboutSubMenu(true);
    }

    const handleNewsSubMenuClick = () => {
        setShowNewsSubMenu(true);
    }
    
    const handleBackClick = () => {
        setShowSubMenuPage(false);
        setShowMenu(true);
        setShowAboutSubMenu(false);
        setShowNewsSubMenu(false);
    }

    let navbarClasses=['navbar'];
    if(scrolled){
        navbarClasses.push('scrolled');
    }

    return (
        <nav className={navbarClasses.join(" ")}>
            <button className="menu-icon" style={{zIndex: 2}} onClick={() => setShowMenu(!showMenu)}>
                {showMenu ? <FaTimes /> : <FaBars />}
            </button>
            <img src={Logo} alt="Logo" className="logo"/>
            {showSubMenuPage ? (
                <ul className="menu active">
                    <li onClick={handleBackClick} className="back-button">Retour</li>
                    <li onClick={closeMenu}><a href="#catalogue">Catalogue</a></li>
                    <li onClick={closeMenu}><a href="#categories">Catégories</a></li>
                    <li onClick={closeMenu}><a href="#pricing">Tarifs</a></li>
                    <li onClick={closeMenu}><a href="#témoignages">Témoignages</a></li>
                    <li onClick={closeMenu}><a href="#faq">FAQ</a></li>
                    <li onClick={closeMenu}><a href="#contact">Contact</a></li>
                </ul>
            ) : showAboutSubMenu ? (
                <ul className="menu active">
                    <li onClick={handleBackClick} className="back-button">Retour</li>
                    <li onClick={closeMenu}><a href="#infos">Informations</a></li>
                    <li onClick={closeMenu}><a href="#contact">Contact</a></li>
                </ul>
            ) : showNewsSubMenu ? (
                <ul className="menu active">
                    <li onClick={handleBackClick} className="back-button">Retour</li>
                    <li onClick={closeMenu}><a href="#event">Evènement</a></li>
                    <li onClick={closeMenu}><a href="#add">Ajouts</a></li>
                    <li onClick={closeMenu}><a href="#calendar">Prochaines Sorties</a></li>
                    <li onClick={closeMenu}><a href="#news">Actualités</a></li>
                </ul>
            ) : (
                <ul className={showMenu ? "menu active" : "menu"}>
                    <li onClick={handleSubMenuClick}><Link to="/home" onClick={scrollToTop}>Accueil</Link></li>
                    <li onClick={handleAboutSubMenuClick}><Link to="/about" onClick={scrollToTop}>A Propos</Link></li>
                    <li onClick={handleNewsSubMenuClick}><Link to="/actu" onClick={scrollToTop}>Actualités et Événements</Link></li>
                    <li onClick={closeMenu}><Link to="/guide" onClick={scrollToTop}>Guide de Démarrage</Link></li>
                </ul>
            )}
            <a href="http://emby.aniflix.fr:8096/">Connexion</a>
        </nav>
    );
}

export default NavBar;
