import React, { useState, useEffect } from 'react';
import './Pricing.scss';

function Pricing() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <div id="pricing">
      <h1>Tarifs</h1>
      <div className="pricing-section">
        <div className="pricing-card">
          <div className="card-title">
            <h2>Premium</h2>
            <p><i className="fa fa-dollar-sign"></i><span>50€ </span>/ An</p>
          </div>
          <div className="card-content">
            <ul>
              <li><i className="fa fa-check-circle"></i>Accès au Catalogue</li>
              <li><i className="fa fa-check-circle"></i>Personnalisation du Profil</li>
              <li><i className="fa fa-check-circle"></i>Ajout de Contenu</li>
              <li><i className="fa fa-times-circle"></i>4 Personnes en Simultané</li>
              <li><i className="fa fa-check-circle"></i>Programme Affiliation</li>
              <li><i className="fa fa-check-circle"></i>Support 7/7</li>
            </ul>
            <div className="button-container">
                <a href="#contact" className="button-link">Acheter</a>
            </div>
          </div>
        </div>
        {windowWidth > 768 && (
          <div className="pricing-infos">
            <div className="pricing-info">
              <h2>Ajout du Contenu</h2>
              <p className="paragraph">Vous ne trouvez pas votre bonheur sur notre plateforme ? Demandez un ajout sur la plateforme : Anime, Films ou Séries, votre contenu préféré sera là dans la semaine.</p>
            </div>
            <div className="pricing-info">
              <h2>Partage de Compte</h2>
              <p className="paragraph">Partagez votre compte avec quelques uns de vos amis, votre compte pourra supporter 4 personnes à la fois, Netflix peut continuer de pleurer.</p>
            </div>
          </div>
        )}
        <div className="pricing-info-right">
          <h2>Affiliation</h2>
          <p className="paragraph">Vous voulez gagner de l'argent ? Vous pensez pouvoir vendre des abonnements ? Dans ce cas vous êtes apte à vous créer un revenu annuel, utilisez le formulaire de Contact pour en savoir plus !</p>
        </div>
      </div>
    </div>
  );
}

export default Pricing;
