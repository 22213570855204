import React from 'react';
import Mobile from '../Components/Guide/Mobile/Mobile';
import Contact from '../Components/Home/Contact/Contact';

function Guide() {
    return (
        <div className="guide">
            <Mobile />
            <Contact />
        </div>
    );
}

export default Guide;