import React from 'react';
import './Banner.scss';

function Banner() {
    return (
        <div className="banner">
            <div className="banner-image"></div>
            <div className="banner-info">
                <h1>Super Mario Bros</h1>
                <p>Un plombier nommé Mario parcourt un labyrinthe souterrain avec son frère, Luigi, essayant de sauver une princesse capturée.</p>
            </div>
        </div>
    );
}

export default Banner;