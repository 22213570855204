import React from 'react';
import './AboutUs.scss';

function AboutUs() {
  return (
    <div className="about-page" id="infos">
      <section className="intro">
        <h1>À Propos de Aniflix</h1>
        <p className='arg'>Aniflix, "l’innovante" plateforme de streaming. Notre objectif ? Faire trembler les géants comme Netflix, Amazon Prime, Disney +, et Crunchyroll et les forcer à baisser leurs prix. Comme le grand Free a fait avec les forfaits téléphoniques. Rien que ça.</p>
      </section>

      <section className="timeline">
        <h2>Notre Histoire</h2>
        <p className='arg'>2023 - Aniflix a été créée dans un garage. Pourquoi ? Pour révolutionner le marché du streaming, bien sûr. Mais surtout parce qu'on n'avait pas assez d'argent pour louer un vrai bureau.</p>
      </section>

      <section className="goals">
        <h2>Nos Objectifs</h2>
        <p className='arg'>Actuellement, on compte 11 utilisateurs fidèles (oui, on les compte encore sur nos doigts). Mais notre ambition ne s'arrête pas là, nous visons les étoiles. Ou au moins, 240 utilisateurs. C'est presque pareil, non ?</p>
      </section>

      <section className="mission-vision">
        <h2>Mission et Vision</h2>
        <p className='arg'>Notre mission, c'est de fournir du streaming de haute qualité à un prix abordable. Mais ne dites ça à personne, c'est un secret. Quant à notre vision, c'est de transformer l'industrie du streaming, ou au moins d'essayer.</p>
      </section>

      <section className="disclaimer">
        <h2>Avertissement</h2>
        <p className='arg'>Et avant que vous ne posiez la question, non, nous ne sommes pas une plateforme de streaming légale. Pas vraiment. Pas encore. Mais chut, ça reste entre nous, d'accord ?</p>
      </section>
    </div>
  );
}

export default AboutUs;
