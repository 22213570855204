import React from 'react';
import Mentions from '../Components/Mentions/Mentions';

function MentionsLegales() {
  return (
    <>
     <Mentions />
    </>
  );
}

export default MentionsLegales;