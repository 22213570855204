import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';

import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';

import './Footer.scss';

const Footer = forwardRef((props, ref) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <footer className="footer" ref={ref}>
      <div className="container">
        <div className="footer__socials">
          <a href="/404" className="footer__social">
            <FacebookIcon />
          </a>
          <a href="https://www.instagram.com/aniflix.officiel/" className="footer__social">
            <InstagramIcon />
          </a>
          <a href="https://twitter.com/Aniflix_x" className="footer__social">
            <TwitterIcon />
          </a>
        </div>
        <ul className="footer__links">
          <li className="footer__link">
            <Link to="/home" onClick={scrollToTop}>Accueil</Link>
          </li>
          <li className="footer__link">
            <Link to="/about" onClick={scrollToTop}>A Propos</Link>
          </li>
          <li className="footer__link">
            <Link to="/actu" onClick={scrollToTop}>Actualités et Evènements</Link>
          </li>
          <li className="footer__link">
            <Link to="/guide" onClick={scrollToTop}>Guide de Démarrage</Link>
          </li>
          <li className="footer__link">
            <Link to="/mentions" onClick={scrollToTop}>Mentions Légales</Link>
          </li>
        </ul>
        <div className="footer__copy">Aniflix - Je jure solennellement que mes intentions sont mauvaises</div>
      </div>
    </footer>
  )
});

export default Footer;
