import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './Add.scss';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';

function AddForm() {
  const [form, setForm] = useState({
    email: '',
    message: '',
  });

  const [messageSent, setMessageSent] = useState(false);

  const handleChange = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();

    emailjs.send('service_ccxssdn', 'template_zhm6jj6', form, '3MPXnAhGJXi88uPDn')
      .then((result) => {
          console.log(result.text);
          setForm({email: '', message: ''});
          setMessageSent(true);
          setTimeout(() => setMessageSent(false), 5000);
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <div id="add">
      <h1 className="title">Ajouts</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Email :
        </label>
        <input type="email" name="email" value={form.email} onChange={handleChange} required />   
        <label>
          Message :
          </label>
          <textarea name="message" value={form.message} onChange={handleChange} required />
        <input type="submit" value="Soumettre" />
      </form>
      {messageSent && <p className="confirmation">Votre message a été envoyé avec succès !</p>}
      <div className="explanation">
        <h2>Ajouts</h2>
        <p className='left'>Il manque un titre sur la plateforme, une fonctionnalité ? Vous pouvez me contacter par email via le formulaire ci-joint !</p>
      </div>
    </div>
  );
}

export default AddForm;