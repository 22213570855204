import React from 'react';
import './Mentions.scss';

function Mentions() {
  return (
    <div className="mentions">
      <div className="mentions-legales">
        <h1>Mentions Légales</h1>
        <h2>Aniflix - Le site pas du tout légal</h2>
        <p>
          Bienvenue sur Aniflix, le site où nous jurons solennellement que nos
          intentions sont mauvaises. Si vous êtes ici, c'est probablement parce
          que vous cherchez des informations légales sur notre site. Mais
          honnêtement, vous n'en trouverez pas...
        </p>
        <h3>Responsabilité</h3>
        <p>
          Si vous rencontrez des problèmes en utilisant notre site, ne nous
          blâmez pas. Nous faisons de notre mieux pour vous fournir du contenu
          de qualité, mais parfois les choses peuvent mal tourner. Dans ce
          cas, nous vous suggérons de créer votre propre site de streaming en ligne, 
          vous verrez c'est simple.
        </p>
        <h3>Droits d'auteur</h3>
        <p>
          Ici, à Aniflix, nous avons un accord spécial avec les studios et les
          producteurs de contenu : nous leur faisons de la publicité gratuite
          en partageant leurs œuvres, et en retour, ils ne nous poursuivent pas
          en justice. Tout le monde y gagne, non ? Le seul problème c'est qu'ils 
          ne sont pas encore au courant pour notre accord...
        </p>
        <h3>Vie privée</h3>
        <p>
          Ne vous inquiétez pas, nous ne vendons pas vos données personnelles à
          des entreprises malveillantes. En fait, nous ne savons même pas
          comment faire ça, et puis vous pensez vraiment que vos données valent
          quelque chose ?
        </p>
        <h3>Contact</h3>
        <p>
          Si vous souhaitez nous contacter pour des questions légales, nous
          vous suggérons de prendre une pause, de respirer profondément et de
          vous rappeler que la vie est trop courte pour s'inquiéter des choses
          sérieuses. Cependant, si vous voulez juste nous dire à quel point
          notre site est génial, n'hésitez pas à nous envoyer un message !
        </p>
      </div>
      </div>
  );
}

export default Mentions;