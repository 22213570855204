import { React, useState, useEffect } from 'react';
import Carousel from '../../Home/Catalogue/Carousel/Carousel';
import './Next.scss';

const TMDB_API_KEY = "57da1142c8ae565eb0a535ea2c0e3fe8"; 

// Liste des ID des titres que vous souhaitez afficher
const nextIds = [ 
  { id: 114461, type: 'tv' },
  { id: 916224, type: 'movie' },
  { id: 667538, type: 'movie' },
  { id: 137040, type: 'tv' },
  { id: 926393, type: 'movie' },
  { id: 210269, type: 'tv' },
  { id: 114472, type: 'tv' },
  { id: 335977, type: 'movie' },
  { id: 447365, type: 'movie' },
  { id: 575264, type: 'movie' },
  { id: 1429, type: 'tv' },
  { id: 967585, type: 'movie' },
  { id: 385687, type: 'movie' },
  { id: 135251, type: 'tv' },
  { id: 106379, type: 'tv' },
  { id: 872585, type: 'movie' },
  { id: 346698, type: 'movie' },
  { id: 30984, type: 'tv' },
  { id: 196454, type: 'tv' },
  { id: 569094, type: 'movie' },
  { id: 218263, type: 'tv' },
  { id: 95479, type: 'tv' },
  { id: 298618, type: 'movie' },
  { id: 976573, type: 'movie' },
];

function Next() {
  const [nextList, setNextList] = useState([]);

  useEffect(() => {
    const promises = nextIds.map(({ id, type }) => 
      fetch(`https://api.themoviedb.org/3/${type}/${id}/images?api_key=${TMDB_API_KEY}`)
        .then(response => response.json())
        .then(data => {
          let poster;
          const englishPoster = data.posters.find(poster => poster.iso_639_1 === 'en');
          const noLanguagePoster = data.posters.find(poster => poster.iso_639_1 === 'null');
          const defaultPoster = data.posters[0];
  
          if (englishPoster) {
            poster = englishPoster.file_path;
          } else if (noLanguagePoster) {
            poster = noLanguagePoster.file_path;
          } else {
            poster = defaultPoster ? defaultPoster.file_path : '';
          }
  
          return poster;
        })
    );
  
    Promise.all(promises)
      .then(nextArray => setNextList(nextArray))
      .catch(err => console.error(err));
  }, []);

  return (
    <div className="anime-section" id="calendar">
      <h1 className='title'>Prochaines sorties</h1>
      <Carousel images={nextList.map(poster => `https://image.tmdb.org/t/p/w500${poster}`)} />
    </div>
  );
}

export default Next;