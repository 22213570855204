// Importation des Modules nécessaires
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Importation des Icônes
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';

// Importation des Composants
import NavBar from './Components/Home/Navbar/Navbar';
import Footer from './Components/Home/Footer/Footer';

// Importation des Pages
import Home from './Pages/Home';
import About from './Pages/About';
import Actu from './Pages/Actu';
import Guide from './Pages/Guide';
import MentionsLegales from './Pages/Mentions';

// Importation des Styles
import './App.scss';

// Pop-Up Rouge pour apporter des infos sur la connexion aux utilisateurs
const RedSnackbar = styled(Snackbar)(({ theme }) => ({
  '& .MuiSnackbarContent-root': {
    backgroundColor: '#b71c1c',
  },
}));

function App() {
  const [open, setOpen] = useState(true);

  // Fermer la Snackbar
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <div className="App">
      <RedSnackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        onClose={handleClose}
        message="Vous êtes un utilisateur ? Appuyez sur 'Connexion' pour accéder au catalogue."
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      <Router>
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/actu" element={<Actu />} />
          <Route path="/guide" element={<Guide />} />
          <Route path="/mentions" element={<MentionsLegales />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;

