import React, { useState, useEffect } from 'react';
import Carousel from '../Carousel/Carousel';
import Card from '../Card/Card';
import './Series.scss';
import Streaming from '../../../../assets/streaming.jpg';
import Stars from '../../../../assets/stars.png';
import Heroes from '../../../../assets/heroes.jpg';

const TMDB_API_KEY = "57da1142c8ae565eb0a535ea2c0e3fe8"; 

const seriesIds = [ 
    119051, 1395, 71446, 456, 93405, 42009, 76479, 113988, 82856, 19885, 83867, 2288, 1399, 2004, 153312, 100088, 1416, 1622, 1639, 78191, 84958, 112738, 69740, 693, 87739, 60625, 217583, 92749, 103768, 96677, 77169, 88236, 69050, 44217, 34388, 1409 
];

function SeriesSection() {
  const [seriesList, setSeriesList] = useState([]);

  const seriesCards = [
    { icon: Heroes, title: '240 Séries à votre disposition', argument: 'Retrouvez des séries difficilement trouvables ailleurs comme Heroes, Dexter ou encore Gossip Girl.' },
    { icon: Streaming, title: 'Mise à jour régulière', argument: 'Nous ajoutons les nouveautés des autres plateformes, vous ne manquerez rien sur Aniflix.' },
    { icon: Stars, title: 'Qualité', argument: 'Chez Aniflix, nous préférons la qualité à la quantité, mais le client est celui qui décide du nouveau contenu sur la plateforme.' },
  ];

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    const promises = seriesIds.map(id => 
      fetch(`https://api.themoviedb.org/3/tv/${id}?api_key=${TMDB_API_KEY}&language=fr`)
        .then(response => response.json())
    );

    Promise.all(promises)
      .then(seriesArray => setSeriesList(seriesArray))
      .catch(err => console.error(err));
  }, []);

  return (
    <div className="series-section">
      <h1 className='title'>Séries</h1>
      <Carousel images={seriesList.map(series => `https://image.tmdb.org/t/p/w500${series.poster_path}`)} />
      <div className="series-cards">
        {windowWidth <= 768 ? seriesCards.slice(0, 1).map((card, index) => (
          <Card key={index} {...card} />
        )) : 
        seriesCards.map((card, index) => (
          <Card key={index} {...card} />
        ))}
      </div>
    </div>
  );
}

export default SeriesSection;
