import React from 'react';
import Banner from '../Components/Home/Banner/Banner';
import Catalogue from '../Components/Home/Catalogue/Catalogue'
import Categories from '../Components/Home/Categories/Categories'
import Pricing from '../Components/Home/Pricing/Pricing'
import FAQ from '../Components/Home/FAQ/FAQ'
import Contact from '../Components/Home/Contact/Contact'
import Testimonials from '../Components/Home/Témoignages/Témoignages'

const Home = () => (
  <div>
    <Banner />
    <Catalogue />
    <Categories />
    <Pricing />
    <Testimonials />
    <FAQ />
    <Contact />
  </div>
);

export default Home;
