import React, { useState } from 'react';
import './FAQ.scss';

function FAQ() {
    const [selectedQuestion, setSelectedQuestion] = useState(null);

    const handleClick = (index) => {
        if (selectedQuestion === index) {
            return setSelectedQuestion(null);
        }
        setSelectedQuestion(index);
    };

    const faqData = [
        { question: 'Sur quelles plateformes est disponible Aniflix ?', answer: 'Aniflix est disponible sur Téléphone, Ordinateur, TV Connectée, Chromecast, Consoles de Jeux...' },
        { question: 'Comment fonctionne le partage de compte ?', answer: "Vous avez juste à transmettre vos informations de connexion à vos proches, vous pouvez aller jusqu'à 4 personnes en simultané sur un même compte." },
        { question: 'Je ne suis pas sûr de vouloir acheter tout de suite...', answer: 'Pourquoi ne pas faire une période d\'essai gratuite d\'une semaine ? Ca ne vous engage à rien, nous ne prenons aucune information personnelle pour cela.' },
        { question: 'Comment fonctionne l\'affiliation ?', answer: 'Il vous suffit d\'encaisser la personne dont vous avez eu la promesse d\'abonnement, et de me transférer la somme prévue (50 €), tandis que vous gardez le reste.' },

    ];

    return (
        <div id="faq">
            <h1>FAQ</h1>
            {faqData.map((item, index) => (
                <div key={index} className="faq-item">
                    <div 
                        className="question"
                        onClick={() => handleClick(index)}
                    >
                        {item.question}
                    </div>
                    {selectedQuestion === index && 
                        <div className="answer">{item.answer}</div>
                    }
                </div>
            ))}
        </div>
    );
}

export default FAQ;